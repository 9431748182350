import React, { useEffect } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import AOS from 'aos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faBook, faDownload, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Layout from '../../components/Layout'
import Section from '../../components/Section'
import Hero from '../../components/Hero'
import TrustedBySection from '../../components/TrustedBySection'
import BookDemoSection from '../../components/BookDemoSection'
import CustomerStorySliderSection from '../../components/CustomerStorySliderSection';

import customerStories from '../../data/customerStories'

import '../au/caanz.scss'
import 'aos/dist/aos.css'

import resource1 from '../../images/caanz/MyHR-CAANZ-Landing_Webinar 31@2x.png'
import resource2 from '../../images/caanz/MyHR-CAANZ-Landing_Effective Employee Onboarding@2x.png'
import resource3 from '../../images/caanz/MyHR-CAANZ-Landing_How to manage employee underperformance@2x.png'

import bannerIcon1 from '../../images/features2023/performance-reviews/Top_Banner_Icons/PNG/MyHR-Platform-All-in-one HR@2x.png'
import bannerIcon2 from '../../images/features2023/performance-reviews/Top_Banner_Icons/PNG/MyHR-Platform-Customised Documents@2x.png'
import bannerIcon3 from '../../images/features2023/performance-reviews/Top_Banner_Icons/PNG/MyHR-Platform-Powered by HR Professionals@2x.png'

import caLogo from '../../images/caanz/MyHR-CAANZ-Landing_CA ANZ Member Logo@2x.png'
import webinarThumb from '../../images/caanz/MyHR-CAANZ-Landing_Sylvie Thrush Marsh@2x.png'


class HubspotForm extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3837251',
          formId: '80bcb5c6-e0fb-4a56-9795-099b1b4a2c43',
          target: '#hubspotForm'
        })
      }
    });
  }
  
  render() {
    return (
      <div id="hubspotForm"></div>
    );
  }
}

export default () => {
  useEffect(() => {
    // Animations
    AOS.init()
  }, [])

  return (
    <Layout
      className="Caanz"
      title="MyHR | CAANZ Discount"
      description="Your all-in-one HR platform. Maximise employee performance. Minimise the admin."
      locale="en-NZ"
    >
      <Hero variant="hr-compliance-and-advisory">
        <h1>The HR Platform for SME.</h1>
        <p>
          A <strong>30% discount on implementation fees</strong> is available exclusively to CA ANZ members. No lock-in contracts, fixed monthly fee.
        </p>
        <div>
          <Button
            color="cyan"
            href="#scrollToForm"
            className="mr-3"
          >
            Book a demo
          </Button>
          <Button
            color="outline-cyan"
            href="/nz/trial?utm_source=landing+page&utm_medium=trial&utm_campaign=caanz+nz"
            className="mr-3 outline"
          >
            Start free trial
          </Button>
          <img src={caLogo} className="ca-logo" />
        </div>
      </Hero>

      <TrustedBySection
        label={<>The trusted, HR platform <br className="d-none d-lg-block"/>for New Zealand SME</>}
        labelCol={{ lg: 4 }}
        items={[
          <img src="//images.ctfassets.net/euxb051oplm7/7tVsftUJJOfWtvtgtABIdc/9929e636dfd04c5f386ee7ab5b5e9e4e/Hell_Pizza_Logo.png" alt="Hell" />,
          <img src="//images.ctfassets.net/euxb051oplm7/4PErs9z5Tq6I14YscMoHO9/1628f87067ae0c2fbcdbdf92569d3eeb/Warriors_Logo.png" alt="The Warriors" />,
          <img src="//images.ctfassets.net/euxb051oplm7/4f6BtGKBVsuftkQNSIPc6N/8a6b34f154cb0c9ac0bfa6c2bd6f936b/MyHR-Home_Resene_2x.png" alt="Resene" className="resene" />,
          <img src="//images.ctfassets.net/euxb051oplm7/6wUzGpHwKkcncC5ucEGDpu/a910f1087c21edbe7a63fe63c9388b98/Mazda_Logo.png" alt="Mazda" />,
          <img src="//images.ctfassets.net/euxb051oplm7/5w72c4FZqWC1Wf4nHOx7II/59badd3061cc0146b0808cf3d01fa426/WWF_Logo.png" alt="WWF" />,
          <img src="//images.ctfassets.net/euxb051oplm7/1lIYDrybf2LGgOWYYtyzEn/bf44e3a23d616ba9c4f2641e62d2742c/MyHR-Home_Lighting_Direct_2x.png" alt="Lighting Direct" className="lightingdirect" />,
        ]}
      />

      <Section className="pb-6 bg-gray" id="scrollToForm">
        <Container>
          <Row>
            <Col md={7} className="px-4 py-4">
              <h1 className="mb-3">An end-to-end HR solution</h1>

              <div className="mainIcons">
                <div>
                  <img src={bannerIcon1} />
                </div>
                <div>
                  <h4>All-in-one HR software</h4>
                  <p>Streamline onboarding, payroll, leave and employee performance, in one place.</p>
                </div>
              </div>
              <div className="mainIcons">
                <div>
                  <img src={bannerIcon2} />
                </div>
                <div>
                  <h4>Customised documents</h4>
                  <p>Take care of compliance with cloud-based contracts, policies and on-demand employment documents, tailor-made for your business.</p>
                </div>
              </div>
              <div className="mainIcons">
                <div>
                  <img src={bannerIcon3} />
                </div>
                <div>
                  <h4>Powered by HR professionals</h4>
                  <p>Get the best outcomes for your team and business with access to on-call HR experts.</p>
                </div>
              </div>

            </Col>
            <Col md={5} className="px-4 mt-4">
              <HubspotForm />
            </Col>
          </Row>
        </Container>
      </Section>

      <CustomerStorySliderSection
        stories={[
          {
            ...customerStories['save-the-kiwi'],
            "moreStoriesButton": true
          }
        ]}
        stars={true}
        theme="light"
      />

      <Section className="WebinarSection">
        <Container>
          <Row className="">
            <Col sm={12} md={8} lg={8} className="leftCol">
              <h3>Register for our upcoming event</h3>
              <h2>Restructuring for sustainable business growth</h2>
              <p>Join Sylvie for an exclusive in-person seminar on getting the legal process right and using a restructure to build a sustainable future.</p>
              <Button
                    color="cyan"
                    href="/nz/landing/caanz-restructuring-for-sustainable-business-growth-auckland"
                    className="mr-3"
                  >
                    Reserve your spot
                </Button>
            </Col>
            <Col sm={12} md={4} lg={4} className="rightCol">
              <img src={webinarThumb} />
              <p>Sylvie Thrush Marsh<br />Lead HR Consultant, MyHR</p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="ResourceSection">
        <h3 className="text-center">Latest HR resources</h3>
        <Container>
          <Row className="mt-6">
            <Col sm={12} md={4} lg={4} className="">
              <div className="card d-flex flex-column justify-content-between h-100">
                <div>
                  <div>
                    <p><FontAwesomeIcon icon={faPlay} /> Webinar #31</p>
                    <h3>Mediation: Why, What and When?</h3>
                  </div>
                  <img src={resource1} />
                </div>
                <div className="d-flex flex-column">
                  <hr />
                  <p>Learn what to expect, the pros and cons of attending, and how mediation can be a powerful tool for resolving disputes before they get nasty.</p>
                  <a className="sectionLinkArrow" href="https://youtu.be/_O1cvGK6eXo">Watch the webinar&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4} lg={4} className="">
              <div className="card d-flex flex-column justify-content-between h-100">
                <div>
                  <div>
                    <p><FontAwesomeIcon icon={faDownload} /> Free Download</p>
                    <h3>Effective employee onboarding in the age of disruption</h3>
                  </div>
                  <img src={resource2} />
                </div>
                <div className="d-flex flex-column">
                  <hr />
                  <p>A broad look at bringing new people into your business, with special emphasis on remote onboarding in the COVID-19 era.</p>
                  <a className="sectionLinkArrow" href="https://info.myhr.works/effective-employee-onboarding-in-the-age-of-disruption">Download the free guide&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4} lg={4} className="">
              <div className="card d-flex flex-column justify-content-between h-100">
                <div>
                  <div>
                    <p><FontAwesomeIcon icon={faBook} /> Blog</p>
                    <h3>How to manage employee underperformance</h3>
                  </div>
                  <img src={resource3} />
                </div>
                <div className="d-flex flex-column">
                  <hr />
                  <p>Expert tools, techniques, and tips for dealing with employees who aren’t meeting performance expectations.</p>
                  <a className="sectionLinkArrow" href="https://blog.myhr.works/managing-underperformance">Read the blog post&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

      <BookDemoSection
        bookDemoLink="#scrollToForm"
        trialLink="/nz/trial?utm_source=landing+page&utm_medium=trial&utm_campaign=caanz+nz"
      />
    </Layout>
  )
}
